export const profile = "hellweg"

export const modules = {
  showSalesman: false,
  showAdditionalPosts: false,
  showPostOffsets: false,
  showWallHeight: false,
  showSupport: true,
  showSideAwning: false,
  showFullFabricBrand: false,
  showRadiantHeater: false,
  showPromoCodes: true,
  showDeliveryPreferences: true,
  showDiscount: false,
  showStatic: false,
  editStatus: false,
  showSalesPrice: true
}

export const options = {
  additionalRoofingOptions: [],
  additionalColorOptions: [],
  awningPositionOptions: [
    "roof",
  ],
  sidePanelVariants: [
    { "value": null, "text": "Bitte Wählen", "disabled": false },
    { "value": "ganzglas_schiebeanlage", "text": "Ganzglas Schiebeanlage", "text_seite": "Keil + Ganzglas Schiebeanlage" },
    { "value": "festelement", "text": "Festelement", "text_seite": "Keil + Festelement" },
    { "value": "dreieck", "text": "Keil" }
  ],
  panelOpt: [
    { "value": null, "text": "Bitte wählen" },
    { "value": "verglasung", "text": "Bodentiefe Verglasung" }
  ],
  additionalLightingOptions: [],
  paymentOptions: [
    { "value": null, "text": "Bitte wählen", "disabled": true },
    { "value": "bm1", "text": "BM 1: 100% bei Auftragsbestätigung" }
  ],
  statusOptions: [
    { id: "sentToManufacturer", title: "Storniert" }
  ],
  typeOptions: [
    {id: "roof", title: "Terrassendach"},
    {id: "carport", title: "Carport"}
  ],
}

export const config = {
  minDepth: 1200,
  minWidth: 2000,
  maxWidth: 7000,
  awningDepth: 5000,
  defaultAwningWidth: true,
  retrievalRegex: /^.{6,}$/
}

export function steps(step: string) {
  return [
    "CustomerInformation",
    "SolarCarport",
    "GeneralData",
    "Awning",
    "Lighting",
    "SidePanels",
    "Payment",
    "Summary"
  ].includes(step)
}

export const logoSrc = "/img/icons/hellweg-logo.png"
export const awningTitle = "Markise (Ansicht vom Garten zur Hauswand)"
export const retrievalNumber = "Bestellnummer"

export const authConfig = {
  VUE_APP_KEYCLOAK_URL: "https://keycloak.cekom.de",
  VUE_APP_KEYCLOAK_REALM: "ldvsg_hellweg"
}